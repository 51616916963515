import * as React from 'react';
import * as styles from './LotteryImage.scss';


class LotteryImage extends React.Component<{}> {
	public static defaultProps = {};

	public render () {

		return (
            <div className={styles.lotteryImage}>
                <img src={require('./Sujet.jpg')} alt="Aktionsfinder der woche"/>
            </div>
		);
    }

}

export default LotteryImage;

import * as React from 'react';
import Button from '../../../../components/General/Button/Button';
import SectionTitle from '../../../../components/Layout/SectionTitle/SectionTitle';
import Icon from '../../../Base/components/Icon/Icon';
import Link from '../../../Base/components/Link/Link';
import ProductPromotionItem from '../../../Promotion/components/ProductPromotionItem/ProductPromotionItem';
import { IProductPromotionType } from '../../../Promotion/types/IProductPromotionType';
import { IMixedEntityType } from '../../types/IMixedEntityType';
import MixedEntityBrandItem from '../MixedEntityGridItem/MixedEntityBrandItem';
import MixedEntityLeafletItem from '../MixedEntityGridItem/MixedEntityLeafletItem';
import MixedEntityStoreItem from '../MixedEntityGridItem/MixedEntityStoreItem';
import MixedEntityVendorItem from '../MixedEntityGridItem/MixedEntityVendorItem';
import * as styles from './MixedEntityGrid.scss';

interface IMixedEntityGridProps {
    items: IMixedEntityType[];
    columns?: '1' | '2' | '3' | '4' | '5';
    title?: string;
    moreLabel?: string;
    moreRoute?: string;
    moreRouteParams?: { [key: string]: any };
    mobileScrollable?: boolean;
    onItemClick?: (entity: IMixedEntityType) => void;
    onItemLoad?: (entity: IMixedEntityType) => void;
}

class MixedEntityGrid extends React.Component<IMixedEntityGridProps, {}> {
    public static defaultProps = {
        columns: 3,
    };

    public render() {
        const { items, title, moreLabel, moreRoute, moreRouteParams, mobileScrollable, columns, onItemClick, onItemLoad, ...restProps } = this.props;

        return (
            <div>
                {!title ? null : <SectionTitle title={title} route={moreRoute} routeParams={moreRouteParams} boxed={true} />}

                <div className={`${styles.MixedEntityGridWrapper} ${mobileScrollable ? styles.MixedEntityGridWrapperScrollable : ''}`}>
                    {items.map((item: IMixedEntityType) => {
                        const itemProps = {
                            'test-id': `mixed-entity-item-${item.slug}`,
                            key: `mixed-entity-item-${item.id}`,
                            columns,
                            data: item,
                            onItemClick,
                            onItemLoad,
                            ...restProps,
                        };

                        // TODO: make types for all mixed entity types to be unified
                        switch (item.type) {
                            case 'BRAND':
                                return <MixedEntityBrandItem {...itemProps} />;
                            case 'LEAFLET':
                                return <MixedEntityLeafletItem {...itemProps} />;
                            case 'PROMOTION':
                                return (
                                    <div className={`${styles.MixedEntityItemWrapper} ${styles['MixedEntityItemWrapperColumns' + columns]}`} key={itemProps.key} test-id={itemProps['test-id']}>
                                        <ProductPromotionItem
                                            promotion={
                                                {
                                                    id: item.id,
                                                    slug: item.slug,
                                                    title: item.title,
                                                    product: {
                                                        brand: {
                                                            name: item.brand && typeof item.brand[0] != 'undefined' ? item.brand[0] : null,
                                                            slug: item.brand && typeof item.brand[0] != 'undefined' ? item.brand[0] : null,
                                                        },
                                                    },
                                                    vendor: {
                                                        name: item.vendorTitle,
                                                        slug: item.vendorSlug,
                                                        resolutions: item.vendorImage,
                                                        id: item.vendorId,
                                                    },
                                                    discountAmount: item.discountAmount,
                                                    discountPercentage: item.discountPercentage,
                                                    validFrom: item.validFrom,
                                                    validTo: item.validTo,
                                                    openValidity: item.openValidity,
                                                    images: [
                                                        {
                                                            resolutions: item.image,
                                                        },
                                                    ],
                                                    couponCode: item.couponCode,
                                                    type: item.promotionType,
                                                    newPrice: item.newPrice,
                                                    originalPrice: item.oldPrice,
                                                } as IProductPromotionType
                                            }
                                            onClick={() => (onItemClick ? onItemClick(item) : null)}
                                            onLoad={() => (onItemLoad ? onItemLoad(item) : null)}
                                        />
                                    </div>
                                );
                            case 'STORE':
                                return <MixedEntityStoreItem {...itemProps} />;
                            case 'VENDOR':
                                return <MixedEntityVendorItem {...itemProps} />;

                            default:
                                return null;
                        }
                    })}

                    {!mobileScrollable ? null : (
                        <div className={styles.MixedEntityGridMoreItemMobile}>
                            <Link route={moreRoute} params={moreRouteParams}>
                                <a className={styles.MixedEntityGridMoreMobile}>
                                    <img src={require('./Aeffchen_haengend.svg')} />

                                    <Button size={'m'} theme="primary" fullWidth={true} customClassName={styles.MixedEntityGridMoreButtonMobile}>
                                        Alle anzeigen
                                    </Button>
                                </a>
                            </Link>
                        </div>
                    )}
                </div>

                {!moreLabel ? null : (
                    <div className={styles.MixedEntityGridMoreItem}>
                        <Link route={moreRoute} params={moreRouteParams}>
                            <a className={styles.MixedEntityGridMore}>
                                <span className={styles.MixedEntityGridMoreIcon}>
                                    <Icon icon="arrow-right" />
                                </span>
                                <span className={styles.MixedEntityGridMoreLabel}>{moreLabel}</span>
                            </a>
                        </Link>
                    </div>
                )}
            </div>
        );
    }
}

export default MixedEntityGrid;

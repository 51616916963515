import * as React from 'react';
import { ILeafletType } from '../../types/ILeafletType';
import * as styles from './LeafletHeader.scss';
import Link from '../../../Base/components/Link/Link';
import { isLeafletTypePremium } from '../../../../utils/leafletTypeChecker';
import { RouteComponentProps, withRouter } from 'react-router';

interface ILeafletInfopostHeaderLogorPropsType {
    leaflet: ILeafletType;
}

class LeafletInfopostHeaderLogo extends React.Component<ILeafletInfopostHeaderLogorPropsType & RouteComponentProps, {}> {
    public static defaultProps = {};

    public render() {
        const { leaflet } = this.props;


        if (isLeafletTypePremium(leaflet.rwvPublishRangeType)) {

            return (
                <Link route="HomeRoute">
                    <a className={styles.leafletHeaderAFLink}>
                        <span>
                            <span className={styles.afLink}>POWERED BY</span>
                            {!isLeafletTypePremium(leaflet.rwvPublishRangeType) ? null : <span className={styles.afNextLeaflets}>Weitere Prospekte entdecken:</span>}
                        </span>

                        <img src={require('./Logo-2-zlg.png')} itemProp="logo" alt="Aktionsfinder Logo" className={styles.desktopLogo} />

                        <img src={require('./logo-xs.png')} alt="Aktionsfinder Logo" className={styles.mobileLogo} />
                    </a>
                </Link>
            );
        }
        return (
            <span className={styles.leafletHeaderAFLink}>
                <span>
                    <span className={styles.afLink}>POWERED BY</span>
                </span>

                <img src={require('./Logo-2-zlg.png')} itemProp="logo" alt="Aktionsfinder Logo" className={styles.desktopLogo} />

                <img src={require('./logo-xs.png')} alt="Aktionsfinder Logo" className={styles.mobileLogo} />
            </span>
        );
    }
}

export default withRouter(LeafletInfopostHeaderLogo);
